import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

// import { WalletKitProvider } from "@mysten/wallet-kit";
// import { WrapperProvider } from "./js/WrapperProvider";
// import { SplashWallet } from "./js/pages/Wallet";
import { SplashWallet } from "./app";

ReactDOM.render(
  <BrowserRouter>
    {/* <WalletKitProvider> */}
    {/* <WrapperProvider /> */}
    <Routes>
      <Route path="/" element={<SplashWallet />} />
    </Routes>
    {/* </WalletKitProvider> */}
  </BrowserRouter>,
  document.getElementById("app")
);

import { useEffect, useState } from "react";
import AppLogo from "../src/assets/img/logo.svg";
import addToHome from "../src/assets/img/AddTohome.svg";
import suiMono from "../src/assets/img/sui-mono.svg";
import aptosMono from "../src/assets/img/aptos.svg";
import venomMono from "../src/assets/img/venom.svg";
import "./common.css";


export function SplashWallet() {
    const [isAndroidDevice, setIsAndroidDevice] = useState(false);
    // identifyDevice
    const identifyDevice = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();

        if (/iphone|ipad|ipod/.test(userAgent)) {
            return "iOS";
        } else if (/android/.test(userAgent)) {
            setIsAndroidDevice(true);
            return "Android";
        } else if (/macintosh/.test(userAgent) && "ontouchend" in document) {
            return "iOS";
        } else if (/macintosh/.test(userAgent)) {
            return "Mac";
        } else if (/windows/.test(userAgent)) {
            setIsAndroidDevice(true);
            return "Windows";
        } else {
            setIsAndroidDevice(true);
            return "Unknown";
        }
    };

    useEffect(() => {
        identifyDevice();
    }, []);

    return (
        <div className="app-container">
            <div className="splash-wrapper">
                <img src={AppLogo} className="app-logo" alt="BlockBolt" />
                <p>Blockchain Payment Protocol</p>
                <div className="blockchain">
                    <p>Choose the Blockchain</p>
                    <div className="blockchainNames">
                        <a href="https://sui.wallet.blockbolt.io" >
                            <button className="wallet-common-button">
                                <img src={suiMono} className="blockchainMono" alt="Sui mono" />
                                Sui Wallet
                            </button>
                        </a>
                        <a href="https://aptos.wallet.blockbolt.io" >
                            <button className="wallet-common-button">
                                <img
                                    src={aptosMono}
                                    className="blockchainMono"
                                    alt="Aptos mono"
                                />
                                Aptos Wallet
                            </button>
                        </a>
                        <a href="https://venom.wallet.blockbolt.io" >
                            <button className="wallet-common-button">
                                <img
                                    src={venomMono}
                                    className="blockchainMono"
                                    alt="Venom mono"
                                />
                                Venom Wallet
                            </button>
                        </a>
                    </div>
                    <p className="splashNote">
                        Note: This is a test wallet and should not be used for real transactions or storing cryptocurrency.
                    </p>
                </div>
                {isAndroidDevice ? <AndroidHomeModal /> : <IosHomeModal />}
            </div>
        </div>
    );
}

function IosHomeModal() {
    return (
        <>
            <div className="homeBg"></div>
            <div className="homeScreenPopup">
                <img src={addToHome} alt="BlockBolt" />
                <h4>Add To Home Screen</h4>
                <p>To install the mobile app</p>
                <p>
                    tap the Share icon in your browser and choose{" "}
                    <span>"Add to Home Screen"</span>
                </p>
            </div>
        </>
    );
}

function AndroidHomeModal() {
    const openPlayStore = () => {
        window.open(
            "https://play.google.com/store/apps/details?id=com.blockbolt.suiwallet",
            "_blank"
        );
    };
    return (
        <>
            <div className="homeAndroidBg"></div>
            <div className="androidHomeScreenPopup">
                <img src={addToHome} alt="BlockBolt" />
                <h4>Add To Home Screen</h4>
                <p>To install the mobile app</p>
                <p>
                    you need to add this website to your home screen or{" "}
                    <span onClick={() => openPlayStore()}>"Install Android App"</span>
                </p>
            </div>
        </>
    );
}
